import React, { useEffect, useState } from 'react';

import { Divider, Drawer, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../../../config/appDefaults';
import {
  checkAndValidateUserSession,
  isDefaultCompanyName,
  isDefaultUsername,
} from '../../../../helpers';
import { getAllowedPages } from '../../../../route-builder';
import CamHelper from './cam-helper';
import CompanyDisplay from './company-display';
import SidebarNav from './sidebar-nav';
import UserDisplay from './user-display';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: SIDEBAR_WIDTH,
    [theme.breakpoints.up('xs')]: {
      marginTop: HEADER_HEIGHT,
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1),
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, userInfo, managingCompanyInfo } = props;

  const classes = useStyles();
  const isCopilot = managingCompanyInfo?.isCopilot;

  const [completedOnBoarding, setCompletedOnBoarding] = useState(true);

  useEffect(() => {
    // check and validate user session immediately
    checkAndValidateUserSession();

    // run validation every 30 seconds thereafter
    const interval = setInterval(() => {
      checkAndValidateUserSession();
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (managingCompanyInfo) {
      if (isDefaultCompanyName(managingCompanyInfo?.managingCompanyName)) {
        setCompletedOnBoarding(false);
      }

      if (managingCompanyInfo.hasProjectManagementProduct) {
        if (userInfo?.email && isDefaultUsername(userInfo.username)) {
          setCompletedOnBoarding(false);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, managingCompanyInfo]);

  if (!completedOnBoarding) {
    return <Redirect to="/onboarding" />;
  }

  const pages = getAllowedPages({ managingCompanyInfo });

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={classes.root}>
        <Grid item flex={0} style={{ paddingBottom: 8 }}>
          <UserDisplay />
        </Grid>
        <Divider />
        <Grid item flex={1} style={{ flexGrow: 1 }}>
          <SidebarNav pages={pages} />
        </Grid>
        {isCopilot && <CamHelper />}
        {managingCompanyInfo && (
          <>
            <Divider />
            <Grid item flex={0} style={{ paddingTop: 8 }}>
              <CompanyDisplay />
            </Grid>
          </>
        )}
      </div>
    </Drawer>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(Sidebar);
