import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import {
  BOOKKEEPING_ALERT_REPORT_PERIOD,
  BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM,
} from '../bookkeeping-alerts.constants';

import {
  DashboardControlBar,
  DashboardControlBarSelect,
} from '../../dashboard';

const BookkeepingAlertsDashboardControlBar = ({
  title,
  alertTypeOptions,
  selectedAlertTypeId,
  setSelectedAlertTypeId,
  selectedReportPeriod,
  setSelectedReportPeriod,
  loading,
  hideAccessExplainer,
  enableDemoMode,
}) => {
  const history = useHistory();

  const goToSettings = () => {
    const searchParams = new URLSearchParams();

    // Set the selected alert type and report period in the URL
    // so that the dashboard page can restore the state when the user navigates back
    searchParams.set('backType', selectedAlertTypeId);
    searchParams.set('backPeriod', selectedReportPeriod);

    history.push(`/alerts/settings?${searchParams.toString()}`);
  };

  const reportPeriodOptions = {
    ...(enableDemoMode
      ? {
          [BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM.value]: BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM,
        }
      : {}),
    ...BOOKKEEPING_ALERT_REPORT_PERIOD,
  };

  return (
    <DashboardControlBar
      title={title}
      hideAccessExplainer={hideAccessExplainer}
    >
      <Grid item style={{ width: 150 }}>
        <DashboardControlBarSelect
          label="Alert Type"
          showOptionAll
          options={alertTypeOptions}
          selected={selectedAlertTypeId}
          loading={loading}
          onChange={setSelectedAlertTypeId}
        />
      </Grid>
      <Grid
        item
        style={{ width: 150 }}
        id="bka-dashboard-control-bar-report-period"
      >
        <DashboardControlBarSelect
          label="Report Period"
          options={reportPeriodOptions}
          selected={selectedReportPeriod}
          loading={loading}
          valuePropName="value"
          onChange={setSelectedReportPeriod}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 32, paddingLeft: 20 }}
          onClick={goToSettings}
          disabled={loading}
          id="bka-dashboard-control-bar-settings-button"
        >
          Settings
          <SettingsIcon style={{ marginLeft: 4 }} />
        </Button>
      </Grid>
    </DashboardControlBar>
  );
};

export default BookkeepingAlertsDashboardControlBar;
